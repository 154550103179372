@font-face { font-family: Alata; src: url('fonts/Alata-Regular.ttf'); } 
@font-face { font-family: Alata; font-weight: bold; src: url('fonts/Alata-Regular.ttf');}

@font-face { font-family: NTR; src: url('fonts/NTR-Regular.ttf'); } 
@font-face { font-family: NTR; font-weight: bold; src: url('fonts/NTR-Regular.ttf');}

@font-face { font-family: MC; src: url('fonts/minecraft-font/MinecraftRegular-Bmg3.otf'); } 
@font-face { font-family: MC; font-weight: bold; src: url('fonts/minecraft-font/MinecraftBold-nMK1.otf');}

* {
  font-family: NTR, sans-serif;
  line-height: 1em;
  /* margin: 50px; */
}

footer {
  /* text-align: right; */
  margin-top: -100px;
  padding: 5px;
  display: flex;
  justify-content: space-between; /* This will push the items to opposite ends */
  align-items: center;
  background-color: #222725;
}

.stroke_text {
  font-size: 40px;
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.stroke_text::after {
  display: block;
  content: '';
  margin-top: 10px;
  border-bottom: 0.5px solid #9DACB2;
}


.about_heading {
  font-size: 40px;
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}


.about_heading::after {
  display: block;
  content: '';
  margin-top: 10px;
  border-bottom: 0.5px solid #9DACB2;
}

.p5_sketch {
  scale: 10;
}

.highlighter_text {
  color: #64ffda;
}


