@media only screen and (max-width:960px){

    #first_row {
      padding-top: 5vh;
    }

    #desktop_tabs {
      visibility: hidden;
      display: none;
    }

    #mobile_tabs {
      visibility: visible;
      display: block;
    }

    .first_bullet_point, .second_bullet_point {
      margin-right: 2em;
      margin-left: 0.5em;
      line-height: 2em;
      font-size: 2.3vh;
    }

    #folder {
      margin-left: -2vh;
    }

    #background_test {
      visibility: visible;
    }

    #typing_text {
      padding-bottom: 20vh;
    }

    .description {
      margin: 10px;
    }

    .portfolio {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .skills, .portfolio, .about {
      text-align: center;
    }

    .mobile_nav {
      position: fixed;
      top: 20px;
      right: 20px;
    }

    .mobile_nav .nav-bar {
      visibility: visible;
      position: fixed;
      top: 1vh;
      right: 0.4vw;
    }

    .wrapper {
      display: none;
    }

    .about {
      margin-top: 11.9vh;
      margin: 22vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .abt_text {
      margin: -20vw;
      margin-top: 1vh;
    }

    .wrappingimage {
      display: none;
    }

    .flip_text {
      margin: 1vh;
    }
  
    .card_wrapper {
      border: 1px solid red;
    }
    
    .skills {
      padding-top: 5vh;
      padding-bottom: -40vh;
      /* width: 90vw; */
      /* margin: 2.5vw; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .swiper, .swiper_wrapper {
        position: absolute;
        width: 90vw;
    }
      
    .swiper-slide:nth-child(1) { /* CHANGE SPECIFIC SWIPER SLIDES */
        background: linear-gradient(132deg, #7A29D8, #0A65C2);
    }
    
    .swiper-slide:nth-child(2) { /* CHANGE SPECIFIC SWIPER SLIDES */
        background: #99ffcc;
    }
    
    .swiper-slide:nth-child(3) { /* CHANGE SPECIFIC SWIPER SLIDES */
        background: #FF5F05;
    }
    
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
    
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    /* new styles added */
    .swiper-button {
        display: flex;
        position: absolute;
        top: calc(50% - 16px);
        z-index: 10;
        cursor: pointer;
        margin: -0.5em;
    }
    
    .swiper-button svg {
        width: 1.5rem;
        height: 1.5rem;
        color: black;
    }
    
    .image-swiper-button-prev {
        left: 5px;
    }
    .image-swiper-button-next {
        right: 5px;
    }
    .swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    
    /* color a single bullet */
    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #fff;
        opacity: 1;
    }
    
    /* target all bullets */
    .swiper-pagination-bullet {
        background-color: #fff;
        opacity: 1;
    }
    
    .igl_text {
        color: #13294B;
    }
    
  
}